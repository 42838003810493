'use client'

import cls from 'classnames'
import { useMemo, useState } from 'react'

import { IconCasino, IconLotto, IconSlot, IconSport } from '@/components/icons'
import { casino, slots, spots } from '@/constants/bf'

export function GamesComponent() {
  // __STATE's
  const [currentTab, setCurrentTab] = useState<number>(0)
  const lists = useMemo(() => {
    return [
      {
        icon: <IconCasino />,
        label: 'คาสิโน'
      },
      {
        icon: <IconSlot />,
        label: 'สล็อด'
      },
      {
        icon: <IconSport />,
        label: 'กีฬา'
      },
      {
        icon: <IconLotto />,
        label: 'หวย'
      }
    ].map((r, index) => ({
      ...r,
      active: index === currentTab
    }))
  }, [currentTab])

  const state = useMemo(() => {
    switch (currentTab) {
      case 0:
        return casino

      case 1:
        return slots

      case 2:
        return spots

      default:
        return [{ image: '/static/images/games/bf/01.png', name: 'Pretty Gaming' }]
    }
  }, [currentTab])

  // __RENDER
  return (
    <div className='ui--home-games'>
      <div className='container rounded-2xl bg-theme-dark p-4'>
        <div className='tabs grid grid-flow-col justify-around gap-4'>
          {lists.map((record, index) => (
            <button
              className={cls('btn btn-tab', { active: record.active })}
              type='button'
              key={index}
              onClick={() => setCurrentTab(index)}>
              <div className='icon flex aspect-square size-12 items-center justify-center rounded-2xl'>
                {record.icon}
              </div>
              <p className='text text-sm'>{record.label}</p>
            </button>
          ))}
        </div>

        <div className='mt-8 grid grid-cols-4 gap-4 max-md:grid-cols-3 max-sm:grid-cols-2'>
          {state.map((record, index) => (
            <div className='' key={index}>
              <img className='w-full object-contain object-center' src={record.image} />
              <p className='py-1 text-center text-sm capitalize'>{record.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
